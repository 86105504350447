export default () => {
    return {
        currentIndex: 0,
        numWords: 0,
        interval: null,
        
        startRotation() {
            this.interval = setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.numWords;
            }, 2000); // Rotate every 2 seconds
        }
    };
}