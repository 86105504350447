import Alpine from "alpinejs";
import focus from '@alpinejs/focus'
import carousel from './carousel';
import textRotator from './text-rotator';
import form from './form-validation';
 
Alpine.data('carousel', carousel);
Alpine.data('textRotator', textRotator);
Alpine.data('form', form);

Alpine.magic('clipboard', () => {
    return subject => navigator.clipboard.writeText(subject)
})

Alpine.plugin(focus)
Alpine.start();