export default () => {
  return {
      prevWidth: window.innerWidth,
      position: 0,
      activeIndex: 1,
      slideMovement: 0,
      carouselContainer: null,
      containerSize: 0,
      mobile: false,
      startingPos: 0,
      timer: null,
      screenSize: (window.innerWidth > 0) ? window.innerWidth : screen.width,
      gap: 0,
      cardsPerSlide: 3,
      totalCards: 0,
      timer: null,
      mouseDown: null,
      mouseUp: null,
      mouseMove: null,
      mouseDrag: false,
      posBeforeMouseMove: null,
      lastTouch: 0,

      carouselFindCard(num){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            let bucket = this.mobile ? parseInt(num) : Math.ceil(parseInt(num) / this.totalCards)
            this.activeIndex = bucket;
            // Update the position to move the carousel to the next slide
            this.position = (this.startingPos + ((this.activeIndex - 1) * this.slideMovement));
            this.posBeforeMouseMove = this.position;
            clearTimeout(this.timer);
        }, 200);
      },

      carouselNavForward() {
          // Clear any existing timer to avoid overlapping actions
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
              if (this.activeIndex < this.totalCards) {
                  // Increment the active index by one
                  this.activeIndex += 1;
                  // Update the position to move the carousel to the next slide
                  this.position = (this.startingPos + ((this.activeIndex - 1) * this.slideMovement));
                  this.posBeforeMouseMove = this.position;
              }
              else {
                  // Increment the active index by one to move into the "dummy slides"
                  let scope = this;
                  this.activeIndex += 1;
                  // Update the position to move the carousel to the next slide
                  this.position = (this.startingPos + ((this.activeIndex - 1) * this.slideMovement));
                  this.posBeforeMouseMove = this.position;
      
                  setTimeout(function () {
                      // Change the active index to the first slide
                      scope.activeIndex = 1;
                      // Remove transition styles from the carousel container for instant position change
                      scope.carouselContainer.style.transition = 'transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s';
                      // Move the carousel to the first slide
                      scope.position = scope.startingPos;
                      scope.position = scope.startingPos + ((scope.activeIndex - 1) * scope.slideMovement);
                      // Restore transition styles 
                      setTimeout(function () {
                          scope.carouselContainer.style.transition = 'transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s';
                      }, 100);
                  }, 420);
              }
      
              // Clear the timer after execution to avoid multiple actions
              clearTimeout(this.timer);
          }, 200);
      },
      
      carouselNavBack(){
          // Clear the timer after execution to avoid multiple actions
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
              if(this.activeIndex > 0 && (this.activeIndex - 1) > 0){    
                  // Decrement the active index by one
                  this.activeIndex-=1;
                  // Update the position to move the carousel back one slide
                  this.position = (this.startingPos + ((this.activeIndex -1) * this.slideMovement));
                  this.posBeforeMouseMove = this.position;

              }
              else{
                  let scope = this;
                  // Decrement the active index by one
                  this.activeIndex-=1;
                  // Update the position to move the carousel back one slide
                  this.position = (this.startingPos + ((this.activeIndex -1) * this.slideMovement));
                  this.posBeforeMouseMove = this.position;

                  setTimeout(function() {
                      // Change the active index to the last slide
                      scope.activeIndex = scope.totalCards;
                      // Remove transition styles from the carousel container for instant position change
                      scope.carouselContainer.style.transition = 'transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s' 
                      scope.position = scope.startingPos;             
                      // Update the position to move the carousel to the last slide                           
                      scope.position = scope.startingPos + ((scope.activeIndex -1) * scope.slideMovement);
                      // Restore transition styles 
                      setTimeout(function() {
                          scope.carouselContainer.style.transition = 'transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s';

                      }, 100);
                  }, 520);                
              }
              // Clear the timer after execution to avoid multiple actions
              clearTimeout(this.timer);
          }, 200);     

      },

      createEmptyTile() {
        let emptyEl = document.createElement("div");
        emptyEl.classList.add('c-cards--carousel__tile-container__tile');
        emptyEl.classList.add('clone-slide');
        return emptyEl;
      },
      
      setupCarousel(){
            this.cardsPerSlide = this.screenSize > 1280 ? 3 : 1;
            const numEmpty =  (Math.ceil(this.carouselContainer.children.length / this.cardsPerSlide) * this.cardsPerSlide) - this.carouselContainer.children.length;
            for (let i = 1; i <= numEmpty; i++) {
                let emptyEl = this.createEmptyTile();
                this.carouselContainer.append(emptyEl.cloneNode());
            }

          let carouselTiles = this.carouselContainer.children;
          this.containerSize = this.carouselContainer.offsetWidth
          this.gap = (0.05 * this.containerSize);
          this.slideMovement = this.containerSize + this.gap;
          this.startingPos = this.screenSize > 1280 ? this.containerSize + this.gap : (this.containerSize + this.gap) * 3;
          this.mobile = this.screenSize <= 1280;
          this.position = this.startingPos;
          this.posBeforeMouseMove = this.position;
          this.totalCards = Math.ceil(carouselTiles.length / this.cardsPerSlide);

          let firstClone = carouselTiles[0].cloneNode(true);
          let secondClone = carouselTiles[1].cloneNode(true);
          let thirdClone = carouselTiles[2].cloneNode(true);
          let lastClone = carouselTiles[carouselTiles.length - 1].cloneNode(true);
          let secondTolastClone = carouselTiles[carouselTiles.length - 2].cloneNode(true);
          let thirdTolastClone = carouselTiles[carouselTiles.length - 3].cloneNode(true);

          firstClone.classList.add('clone-slide');
          if(firstClone.firstElementChild && firstClone.firstElementChild.getElementsByTagName("template")[0]) firstClone.firstElementChild.removeChild(firstClone.firstElementChild.getElementsByTagName("template")[0])
          secondClone.classList.add('clone-slide');
          if(secondClone.firstElementChild && secondClone.firstElementChild.getElementsByTagName("template")[0]) secondClone.firstElementChild.removeChild(secondClone.firstElementChild.getElementsByTagName("template")[0])
          thirdClone.classList.add('clone-slide');
          if(thirdClone.firstElementChild && thirdClone.firstElementChild.getElementsByTagName("template")[0]) thirdClone.firstElementChild.removeChild(thirdClone.firstElementChild.getElementsByTagName("template")[0])
          lastClone.classList.add('clone-slide');
          if(lastClone.firstElementChild && lastClone.firstElementChild.getElementsByTagName("template")[0]) lastClone.firstElementChild.removeChild(lastClone.firstElementChild.getElementsByTagName("template")[0])
          secondTolastClone.classList.add('clone-slide');
          if(secondTolastClone.firstElementChild && secondTolastClone.firstElementChild.getElementsByTagName("template")[0]) secondTolastClone.firstElementChild.removeChild(secondTolastClone.firstElementChild.getElementsByTagName("template")[0])
          thirdTolastClone.classList.add('clone-slide');
          if(thirdTolastClone.firstElementChild && thirdTolastClone.firstElementChild.getElementsByTagName("template")[0]) thirdTolastClone.firstElementChild.removeChild(thirdTolastClone.firstElementChild.getElementsByTagName("template")[0])

          this.carouselContainer.insertBefore(firstClone, this.carouselContainer.lastChild.nextSibling);
          this.carouselContainer.insertBefore(secondClone, this.carouselContainer.lastChild.nextSibling);
          this.carouselContainer.insertBefore(thirdClone, this.carouselContainer.lastChild.nextSibling);

          this.carouselContainer.insertBefore(lastClone, this.carouselContainer.firstChild);
          this.carouselContainer.insertBefore(secondTolastClone, this.carouselContainer.firstChild);
          this.carouselContainer.insertBefore(thirdTolastClone, this.carouselContainer.firstChild);

          let scope = this;
          this.carouselContainer.addEventListener("touchstart", function(e){ scope.carouselTouchMovement(e, scope, "mobile") });
      },

      resizeCarousel(){
        var width = window.innerWidth;
        if (width !== this.prevWidth) {
            this.prevWidth = width;
        
            this.screenSize = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            let carouselTiles = this.carouselContainer.children;
            this.containerSize = this.carouselContainer.offsetWidth
            this.gap = (0.05 * this.containerSize);
            this.slideMovement = this.containerSize + this.gap;
            this.startingPos = this.screenSize > 1280 ? this.containerSize + this.gap : (this.containerSize + this.gap) * 3;
            this.mobile = this.screenSize <= 1280;
            this.position = this.startingPos;
            this.posBeforeMouseMove = this.position;
            this.cardsPerSlide = this.screenSize > 1280 ? 3 : 1;
            this.totalCards = Math.ceil((carouselTiles.length - 6) / this.cardsPerSlide);
            this.activeIndex = 1;

            let elementsToRemove = this.carouselContainer.querySelectorAll('.clone-slide');
            elementsToRemove.forEach(element => {
                element.remove();
            });

            this.setupCarousel();
        }
      },

      carouselTouchMovement(evt, scope, deviceType){
        const el = evt.currentTarget;
        let deviceMove, deviceUp, deviceDown;
        scope.lastTouch = evt.touches[0].screenX;
        deviceMove = "touchmove";
        deviceUp = "touchend";
        deviceDown = "touchstart";

        const move = (evt) => {
            let movement = evt.touches[0].screenX - scope.lastTouch;
            if (movement > 90 || movement < -90) {
                evt.preventDefault()
                evt.target.parentNode.parentNode.classList.add("dragging");
                scope.lastTouch = evt.touches[0].screenX;
                scope.position -= movement;

                if(movement > 0){
                    scope.carouselNavBack();
                    up;
                }
                else if(movement < 0){
                    scope.carouselNavForward();
                    up;
                }
                else{
                    up;
                }
            }
        };
        const up = (evt) => {
            removeEventListener(deviceMove, move);
            removeEventListener(deviceUp, up);
            scope.lastTouch = 0;
            evt.target.classList.remove("dragging")
            setTimeout(() => {
                if(scope.position % scope.slideMovement != 0){
                    scope.position = scope.posBeforeMouseMove;
                }
            }, 200);
        };
        addEventListener(deviceMove, move);
        addEventListener(deviceUp, up);
    },

  }
}


